#root {
  overflow: hidden;
  font-family: Arial, Helvetica, sans-serif;
}

a:link {
  color: rgb(25, 51, 73);
  text-decoration: none;
  font-weight: bold;
}

a:visited {
  color: rgb(25, 51, 73);
}

a:hover {
  text-decoration: underline;
}

/* Home */

.home {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
}

/* Home - Modal */

.home .modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 30px;
  padding-top: 50px;
  width: 100%;
  height: 100%;
  max-width: 500px;
  max-height: 600px;
  z-index: 9999999999;
  background-color: white;
  box-sizing: border-box;
  overflow-y: auto;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.home .modal .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px 10px;
  font-size: 40px;
  cursor: pointer;
  color: rgba(25, 51, 73, 0.7);
}

.home .modal .close:hover {
  color: rgba(25, 51, 73, 1);
}

/* Home - Menu */

.home .menu {
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;
  padding: 20px;
  box-sizing: border-box;
  background-color: white;
}

.home .menu.open {
  width: 408px;
  max-width: 100%;
}

.home .menu.closed {
  width: 40px;
}

.home .menu.closed .closed {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  text-align: center;
  padding-top: 21px;
  font-size: 22px;
  color: rgb(25, 51, 73);
  cursor: pointer;
}

.home .menu.closed .rotate {
  width: 250px;
  transform: rotate(90deg);
  transform-origin: left;
  z-index: 9999999;
  padding-bottom: 36px;
  font-size: 18px;
}

.home .menu .boundary {
  height: 100%;
  background-image: linear-gradient(
    90deg,
    rgba(25, 25, 25, 0.4),
    rgba(0, 0, 0, 0)
  );
  width: 15px;
  position: absolute;
  right: -15px;
  pointer-events: none;
}

.home .menu .date {
  color: rgb(25, 51, 73);
}

.home .menu .date table {
  margin: auto;
}

.home .menu .tagline {
  background-color: #f7fafb;
  padding: 10px;
  text-align: center;
  color: rgb(25, 51, 73);
}

.home .menu .tagline .line1 {
  font-size: 30px;
}

.home .menu .tagline .line2 {
  font-size: 18px;
  padding-top: 3px;
}

.home .menu .buttons {
  position: absolute;
  bottom: 20px;
  width: 100%;
  text-align: right;
  left: 0;
}

.home .menu .buttons button {
  font-size: 16px;
  padding: 10px 20px;
  cursor: pointer;
  margin-right: 20px;
}

.home .menu .buttons button:hover {
  text-decoration: underline;
}

.home .menu .buttons button.white {
  color: rgb(25, 51, 73);
  background-color: white;
  border: none;
}

.home .menu .buttons button.blue {
  color: white;
  background-color: rgb(25, 51, 73);
  border: none;
  border-radius: 5px;
}

.home .menu .selector .titles {
  padding: 20px 0px;
}

.home .menu .selector .titles .title {
  display: inline-block;
  color: rgb(198, 211, 216);
  font-size: 22px;
  font-weight: bold;
  padding: 0px 15px;
  cursor: pointer;
}

.home .menu .selector .titles .title:hover {
  color: rgb(25, 51, 73);
}

.home .menu .selector .titles .title.active {
  color: rgb(25, 51, 73);
}

.home .menu .selector .contents .content {
  position: absolute;
  padding: 10px;
  width: calc(100% - 40px);
  box-sizing: border-box;
}

.home .menu .selector .contents .content.hide {
  visibility: hidden;
}

.home .menu .selector .contents .content .list {
  width: 100%;
  background-color: #f7fafb;
  margin: 5px 0px;
  padding: 5px;
  box-sizing: border-box;
}

.home .menu .selector .contents .content .list .MuiSlider-root {
  color: rgb(25, 51, 73);
}

.home .menu .selector .contents .content .list table {
  width: 100%;
}

.home .menu .selector .contents .content .list .settings {
  padding: 0 10px 0 35px;
  width: 100%;
  box-sizing: border-box;
}

.home .menu .selector .contents .content .list .settings .color {
  padding-top: 5px;
}

.home .menu .selector .contents .content img.image {
  width: 20px;
  cursor: pointer;
  padding: 3px;
}

.home .menu .selector .contents .content img.image.fade {
  opacity: 0.3;
}

.home .menu .selector .contents .content img.image.fade:hover {
  opacity: 1;
}

@media screen and (max-width: 500px) {
  .home .menu .tagline .line1 {
    font-size: 22px;
  }

  .home .menu .tagline .line2 {
    font-size: 14px;
    padding-top: 0;
  }

  .home .menu .selector .titles {
    padding: 10px 0 0 0;
  }

  .home .menu .selector .titles .title {
    display: inline-block;
    color: rgb(198, 211, 216);
    font-size: 20px;
    font-weight: bold;
    padding: 0px 8px;
    cursor: pointer;
  }
}

/* Home - Map */

.home .main {
  right: 0;
  top: 0;
  position: fixed;
  height: 100%;
}

.home .main.open {
  width: calc(100vw - 408px);
}

.home .main.closed {
  width: calc(100% - 40px);
}

.home .main .basemap {
  position: absolute;
  height: calc(100% - 100px);
  width: 100%;
}

.home .main .basemap img.basemap-logo {
  position: absolute;
  right: 5px;
  bottom: 20px;
  z-index: 999;
  width: 50px;
  pointer-events: none;
}

/* Home - About */

.home .about {
  background-color: #f7fafb;
  position: absolute;
  top: 20px;
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  padding: 40px;
  box-sizing: border-box;
  z-index: 999;
  left: 20px;
}

.home .about .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px 10px;
  font-size: 40px;
  cursor: pointer;
  color: rgba(25, 51, 73, 0.7);
}

.home .about .close:hover {
  color: rgba(25, 51, 73, 1);
}

.home .about .inner {
  overflow: auto;
  height: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.home .about .inner::-webkit-scrollbar {
  display: none;
}

.home .about img {
  width: 100%;
  max-width: 600px;
}

/* Home - Select Basemap */

.home .selectbasemap {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 999;
}

.home .selectbasemap .minimap {
  height: 80px;
  width: 80px;
  cursor: pointer;
}

.home .selectbasemap .minimap .leaflet-container {
  border-radius: 20px;
  border: 1px solid white;
  box-shadow: 0px 0px 5px #fff;
}

.home .selectbasemap .minimap {
  display: inline-block;
  padding-left: 20px;
}

.home .selectbasemap .minimap.hide {
  visibility: hidden;
}

.home .selectbasemap:hover .minimap.hide {
  visibility: visible;
}

.home .selectbasemap .minimap .leaflet-control-attribution {
  display: none;
}

/* Home - Time Selector */

.home .timeselector {
  position: absolute;
  bottom: 0;
  z-index: 999;
  width: 100%;
  height: 100px;
  cursor: pointer;
  box-sizing: border-box;
  padding: 20px 20px 0 20px;
}

.home .timeselector .boundary {
  width: 100%;
  background-image: linear-gradient(
    0deg,
    rgba(25, 25, 25, 0.4),
    rgba(0, 0, 0, 0)
  );
  height: 15px;
  position: absolute;
  top: -15px;
  pointer-events: none;
}

.home .timeselector .datevalue {
  display: none;
  position: absolute;
  top: -45px;
  font-size: 26px;
  left: 5px;
  background-color: #f7fafb;
  color: rgb(25, 51, 73);
  padding: 5px 10px;
  border-radius: 5px;
}

.home .timeselector .xaxis {
  font-size: 15px;
  color: rgb(25, 51, 73);
  font-weight: bold;
}

.home .timeselector .xaxis .domain {
  stroke-width: 0;
}

@media screen and (max-width: 500px) {
  .home .timeselector .datevalue {
    font-size: 22px;
  }
}

/* Home - Loading */

.home .loading {
  display: none;
  position: absolute;
  z-index: 999;
  top: 50%;
  left: 50%;
}

.loader {
  border: 4px solid rgba(198, 211, 216, 0.4);
  border-radius: 50%;
  border-top: 4px solid rgb(25, 51, 73);
  width: 30px;
  height: 30px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Color Selector */

.color-selector {
  width: 100%;
}

.color-selector .inner-color {
  height: 20px;
  display: inline-block;
  opacity: 0.6;
  cursor: pointer;
}

.color-selector .inner-color.active {
  box-shadow: 0 0 0 2px rgb(25, 51, 73);
  opacity: 1;
}

.color-selector .inner-color:hover {
  box-shadow: 0 0 0 2px rgb(25, 51, 73);
  opacity: 1;
}

/* Basemap Leaflet */

.leaflet-zoom-animated {
  cursor: move;
}

/* CSS Functions */

.fade-in {
  animation: fadeIn ease 2s;
  -webkit-animation: fadeIn ease 2s;
  -moz-animation: fadeIn ease 2s;
  -o-animation: fadeIn ease 2s;
  -ms-animation: fadeIn ease 2s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
